<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb :breadcrumbs="[{ label: 'Сотрудники' }]" />
      <h4 class="page-title">Сотрудники</h4>
    </div>
    <GridView
      :service="service"
      :export="false"
      :columns="[
        {
          field: 'surname',
          header: 'ФИО',
          sortable: true,
          slot: 'nameSlot',
        },
        {
          field: 'workPos',
          header: 'Должность',
          sortable: true,
        },
        {
          field: 'status',
          header: 'Статус',
          slot: 'statusEmployee',
        },
        {
          field: 'taskList',
          header: 'Задачи',
          slot: 'taskColumn',
        },
        {
          actions: [
            {
              action: 'show',
              routeName: 'UserShow',
            },
            {
              action: 'edit',
              routeName: 'UserEdit',
            },
          ],
        },
      ]"
      :search="[
        {
          label: 'Фамилия',
          field: 'surname',
        },
        {
          label: 'Имя',
          field: 'name',
        },
        {
          label: 'Отчество',
          field: 'secondName',
        },
        {
          label: 'Должность',
          field: 'workPos',
        },
      ]"
    >
      <template #statusEmployee="{ data }">
        <span v-if="data.tripToday" class="text-danger">{{
          data.tripToday
        }}</span>
        <span v-else-if="data.vacationToday" class="text-warning"
          >В отпуске до {{ data.vacationToday }}</span
        >
        <span v-else-if="data.sickLeaveToday" class="text-warning"
          >На больничном до {{ data.sickLeaveToday }}</span
        >
        <span v-else class="text-success">В офисе</span>
      </template>
      <template #taskColumn="{ data }">
        <span
          @click="showTaskList(data)"
          v-if="data.taskList.length"
          class="text-info bold cursor-pointer"
          >{{ data.taskList.length }}</span
        >
      </template>
      <template #nameSlot="{ data }">
        <router-link
          class="text-info link_underline"
          :to="{ name: 'UserShow', params: { id: data.id } }"
        >
          {{ data.surname }} {{ data.name }} {{ data.secondName }}
        </router-link>
      </template>
    </GridView>
    <Dialog
      :header="'Задачи ' + curUserName"
      modal
      v-model:visible="displayTaskList"
    >
      <span v-for="task in this.curTaskList" :key="task.id">
        <router-link
          class="text-info link_underline"
          :to="{ name: 'TaskShow', params: { id: task.id } }"
        >
          <span>
            {{ task.name }}
          </span>
        </router-link>
        <span
          :class="{
            'text-danger': task.status == 0,
            'text-warning': task.status == 1,
            'text-success': task.status == 2,
          }"
          style="float: right"
          >{{ statusName(task.status) }}</span
        ><br />
      </span>
    </Dialog>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb";
import GridView from "@/components/ui/GridView.vue";
import UserService from "@/modules/user/services/UserService";
import Str from "@/utils/Str";
import Dialog from "primevue/dialog";

export default {
  components: {
    BreadCrumb,
    GridView,
    Dialog,
  },
  data() {
    return {
      Str: Str,
      userList: {},
      service: new UserService({
        expand: ["taskList"],
      }),
      curUserName: "",
      curTaskList: {},
      displayTaskList: false,
    };
  },
  methods: {
    statusName(status) {
      switch (status) {
        case 0:
          return "Не выполнена";
        case 1:
          return "Выполняется";
        case 2:
          return "Выполнена";
      }
    },
    showTaskList(data) {
      this.curUserName = data.surname + " " + data.name + " " + data.secondName;
      this.curTaskList = data.taskList;
      this.displayTaskList = true;
    },
  },
};
</script>
